import './odd.scss'
import CustomHTMLElement from 'html-element'
import runnerOddsApi from 'networking/api/runners/odds'
import SpriteSvg from 'elements/svg'

const ODDS_UPDATE_INTERVAL = 60 * 1000

export default class RunnerOdd extends CustomHTMLElement {
  _runSetup() {
    super._runSetup()

    this.raceId = this.getIntegerAttribute('data-race-id')
    this.runnerId = this.getIntegerAttribute('data-runner-id')
    this.future = this.getBooleanAttribute('data-future')
    this.marketCode = this.getAttribute('data-market')

    this.mode = this.raceId ? 'race' : 'runner'

    this.setupElements()
    this.addEventListener('click', this.clicked)
    updateOddsWhenReady()
  }

  setupElements() {
    if (this.mode === 'race') {
      this.rugIcon = new SpriteSvg()
      this.appendChild(this.rugIcon)
    }

    this.bookmakerLogo = new SpriteSvg()
    this.bookmakerLogo.classList.add('runner-odd__bookmaker')
    this.appendChild(this.bookmakerLogo)

    this.priceElement = document.createElement('span')
    this.priceElement.classList.add('runner-odd__price')
    this.appendChild(this.priceElement)
  }

  get currentOdds() {
    return this._currentOdds || []
  }
  set currentOdds(odds) {
    this._currentOdds = odds
    this.displayOdds()
  }

  marketOdds(marketCode) {
    return this.currentOdds.filter((odd) => odd.market.code === marketCode && odd.bookmaker.code === 'ladbrokes')
  }

  displayOdds() {
    let odds = this.marketOdds(this.marketCode)
    let odd = odds && odds.length > 0 ? odds[0] : null

    if (odd == null || odd.price == null || odd.price === 0) {
      this.bookmakerLogo.iconName = 'bookmaker_ladbrokes_square'
      this.priceElement.innerText = 'N/A'
    } else {
      if (this.displayOdd) {
        if (this.displayOdd.price < odd.price) {
          this.displayIncrease()
        } else if (odd.price < this.displayOdd.price) {
          this.displayDecrease()
        }
      }

      this.displayOdd = odd
      this.priceElement.innerText = odd.price.toFixed(2)
      this.bookmakerLogo.iconName = 'bookmaker_ladbrokes_square'

      if (this.rugIcon && this.mode === 'race' && this.displayOdd.runner) {
        this.rugIcon.iconName = `rug_${this.displayOdd.runner.drawn_box}`
      } else if (this.rugIcon) {
        this.rugIcon.iconName = null
      }
    }
  }

  displayDecrease() {
    this.classList.add('runner-odd--decreasing')
    setTimeout(() => this.classList.remove('runner-odd--decreasing'), 1000)
  }

  displayIncrease() {
    this.classList.add('runner-odd--increasing')
    setTimeout(() => this.classList.remove('runner-odd--increasing'), 1000)
  }

  clicked(event) {
    event.stopPropagation()
    if (this.displayOdd) {
      window.open(`/api/runners/odds/${this.displayOdd.id}/redirect`, '_blank')
    }
  }
}

window.customElements.define('runner-odd', RunnerOdd)

let oddsUpdateTimer = null
let updatingOdds = false

function updateOddsWhenReady() {
  if (oddsUpdateTimer) {
    window.clearTimeout(oddsUpdateTimer)
  }
  oddsUpdateTimer = window.setTimeout(updateOdds, 100)
}

function updateOdds() {
  if (updatingOdds) return
  updatingOdds = true

  let oddsElements = Array.from(document.querySelectorAll('runner-odd'))
  if (oddsElements.length === 0) return

  let ids = {
    raceIds: oddsElements.filter((el) => el.mode === 'race' && !el.future).map((el) => el.raceId),
    futureRaceIds: oddsElements.filter((el) => el.mode === 'race' && el.future).map((el) => el.raceId),
    runnerIds: oddsElements.filter((el) => el.mode === 'runner' && !el.future).map((el) => el.runnerId),
    futureRunnerIds: oddsElements.filter((el) => el.mode === 'runner' && el.future).map((el) => el.runnerId)
  }

  runnerOddsApi.all(ids).then((response) => {
    oddsUpdateTimer = window.setTimeout(updateOdds, ODDS_UPDATE_INTERVAL)
    updatingOdds = false

    let races = response.content.race_odds
    let futureRaces = response.content.future_race_odds
    let runners = response.content.runner_odds
    let futureRunners = response.content.future_runner_odds

    oddsElements.forEach((element) => {
      let odds = null
      if (element.future) {
        odds = element.mode === 'race' ? (futureRaces && futureRaces[element.raceId]) : (futureRunners && futureRunners[element.runnerId])
      } else {
        odds = element.mode === 'race' ? (races && races[element.raceId]) : (runners && runners[element.runnerId])
      }

      element.currentOdds = odds
    })
  })
}
